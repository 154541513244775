<template>
  <div class="parking-operation-wrapper">
    <title-bar :title="'车场营收分析'"></title-bar>
    <div class="page-box">
      <operation :locationObj="locationObj">
        <div class="selector-item-box" @click="show = true">
          <div class="selector-icon"></div>
          <div class="selector-txt">{{ pickerTxt }}</div>
        </div>
      </operation>
      <!-- <div class="total-revenue">
        <total-revenue></total-revenue>
      </div>
      <div class="total-operation">
        <total-operation></total-operation>
      </div>
      <div class="monthly-operation">
        <monthly-operation></monthly-operation>
      </div>
      <div class="annual-operation">
        <annual-rate></annual-rate>
      </div> -->
    </div>
    <van-popup v-model="show" position="bottom">
      <tab-select
        @selectAll="onSelectAll"
        @selectFinish="onSelectFinish"
      ></tab-select>
    </van-popup>
  </div>
</template>

//
<script>
import Operation from "../components/Operation.vue";
import TabSelect from "../views/tabSelect.vue";
// import TotalRevenue from "../components/Operation/TotalRevenue.vue";
// import TotalOperation from "../components/Operation/TotalOperation.vue";
// import MonthlyOperation from "../components/Operation/monthlyOperation.vue";
// import AnnualRate from "../components/Operation/annualRate.vue";

export default {
  components: {
    Operation,
    TabSelect
    //   TotalRevenue,
    //   TotalOperation,
    //   MonthlyOperation,
    //   AnnualRate
  },
  data: () => ({
    show: false,
    pickerTxt: "全部",
    locationObj: {}
  }),
  methods: {
    onSelectFinish(obj) {
      console.log(obj);
      this.pickerTxt = obj.txt;
      this.locationObj = {
        tenantId: obj.id0,
        parkingId: obj.id2
      };
      this.show = false;
    },

    onSelectAll() {
      this.pickerTxt = "全部";
      this.locationObj = {};
      this.show = false;
    }
  }
};
</script>

<style lang="less" scoped>
.parking-operation-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.page-box {
  flex: 1;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #f0f2f5;
}

.selector-item-box {
  display: flex;
  align-items: center;
  overflow: hidden;

  .selector-icon {
    width: 0;
    height: 0;
    border-top: 6px solid #000;
    border-left: 6px solid transparent;
    border-bottom: 0 solid transparent;
    border-right: 6px solid transparent;
    margin: 0 2px;
  }
}
</style>
